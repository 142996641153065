<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1 >Dashboard</h1>

        </ng-container>
        <h1 *ngIf="manageReportedPostsHeading">Manage Reported Content</h1>
        <h1 *ngIf="manageClientHeading">Manage Clients</h1>
        <h1 *ngIf="manageServicesHeading">Manage Services</h1>
        <h1 *ngIf="manageTutorialHeading">Manage Tutorial</h1>
        <h1 *ngIf="manageConvenienceHeading">Manage Community</h1>
        <h1 *ngIf="manageDiseasesHeading">Invite User</h1>
      
        <h1 *ngIf="dashboardHeading">Dashboard</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="manageInterestdHeading">Manage Genres</h1>
        <h1 *ngIf="manageLocalHeading">Manage Artists/Community</h1>
        <h1 *ngIf="broadcastNotificationHeading">Broadcast Notifications</h1>



    </div>


    <div class="right-sec">
        <div class="btn-group" dropdown placement="bottom right">
            <!-- <button id="button-alignment" dropdownToggle type="button" class="btn dropdown-toggle"
                aria-controls="dropdown-alignment">

                {{userName}}

                <img class="drop-down-arrow" src="../../../assets/icons/drop-down-arrow.svg" alt="">
            </button> -->
            <!-- <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu"
                aria-labelledby="button-alignment">
                <li role="menuitem"><a class="dropdown-item admin-dropdown" routerLink="/edit-profile">Change
                        Password</a></li>
                <li style="cursor: pointer;" role="menuitem"><a class="dropdown-item admin-dropdown"
                        (click)="openModal(template)">Logout</a></li>
            </ul> -->
        </div>

    </div>
</div>
