import { Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
// import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';
import { HeaderService } from 'src/app/shared/services/header.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AuthService } from '../auth/services/auth.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent implements OnInit, OnDestroy {

  passwordForm: FormGroup;
  allFieldsEntered = false;
  match = true;
  passwordMatchError = false;
  hide = true;
  hide1 = true;
  hide2 = true;



  userType: any;
  message: string;
  imagePath: any;
  imgURL: any;
  uploaded: boolean = false;
  fileName: any;
  currentUser: any;
  passChangeMsg: boolean = false;
  modalRef: BsModalRef;

  submitted: boolean = false;
  password: boolean = false;
  changed: boolean = false;
  strongPasswordError: boolean = false;
  samePasswordError: boolean = false;
  // isNewPasswordSameAsOldPassword = false;




  imgChangeEvt: any = '';
  cropImgPreview: any = '';
  singalurl: any;
  selectedFile: any;
  @ViewChild('template2') template2: TemplateRef<any>;
  @ViewChild('myFile2') myFile2: ElementRef;

  // @HostListener("click")
  // clicked() {
  //   this.myFile2.nativeElement.value = '';
  //   // this.eventForm.controls['myFile'].setValue('');
  // }




  // passwordForm = new FormGroup({
  //   oldPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
  //   newPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]),
  //   confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]),
  // })

  get p() {
    return this.passwordForm.controls;
  }

  constructor(
    private _header: HeaderService,
    private authService: AuthService,
    private sharedService: SharedService,
    private router: Router,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder
  ) {
    this.passwordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.compose([
        Validators.required,
        Validators.pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])[A-Za-z\d@$!%*?&~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/]{6,}$/)
      ])],
      
      confirmPassword: ['', Validators.required]
    });
  }

  lang: string = 'eng';
  ngOnInit(): void {

    let currentUser = localStorage.getItem('currentUser');
    this.userType = JSON.parse(currentUser).userType;
    this.lang = localStorage.getItem('lang');
    this._header.changePasswordHeading.next(true);
    this.currentUser = this.authService.currentUserValue;
  }
  ngOnDestroy() {
    this._header.changePasswordHeading.next(false);
  }

  // confirmation modal box
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }


  // match: boolean = true;
  changePassword() {
    this.changed = true;
    if (this.passwordForm.invalid || this.passwordForm.pristine || !this.match) return;
  
    const oldPassword = this.passwordForm.value.oldPassword;
    const newPassword = this.passwordForm.value.newPassword;
  
    if (oldPassword === newPassword) {
      this.toastr.error("New password cannot be the same as the old password");
      return;
    }
  
    let data = {
      userLoginId: this.currentUser.userLoginId,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };
  
    this.authService.changePassword(data).subscribe((res: any) => {
      console.log('changepassword res', res);
      if (res.type === "success") {
        this.passwordForm.reset();
        this.toastr.success("Password Changed Successfully");
        localStorage.removeItem('currentUser');
        this.router.navigateByUrl('/login');
      } else if (res.type === "error") {
        this.toastr.error(res.message, "Error");
      }
    });
  }
  

  
  checkFieldsEntered() {
    const { oldPassword, newPassword, confirmPassword } = this.passwordForm.value;
    this.allFieldsEntered = oldPassword && newPassword && confirmPassword;
    this.match = newPassword === confirmPassword;
    this.samePasswordError = oldPassword === newPassword;

    const newPasswordControl = this.passwordForm.get('newPassword');
    if (newPasswordControl.errors) {
      this.strongPasswordError = newPasswordControl.errors.pattern;
    } else {
      this.strongPasswordError = false;
    }
  }

  passwordsMatchValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const oldPassword = control.get('oldPassword').value;
      const newPassword = control.get('newPassword').value;

      if (oldPassword !== newPassword) {
        return null;
      }

      return { samePassword: true };
    };
  }

  // get isNewPasswordSameAsOldPassword(): boolean {
  //   const oldPassword = this.passwordForm.get('oldPassword').value;
  //   const newPassword = this.passwordForm.get('newPassword').value;

  //   return oldPassword === newPassword;
  // // }
  isNewPasswordSameAsOldPassword() {
    const oldPassword = this.passwordForm.get('oldPassword').value;
    const newPassword = this.passwordForm.get('newPassword').value;
  
    this.password = oldPassword === newPassword;
  }
  
  

  matchConfirmPassword() {
    const newPassword = this.passwordForm.value.newPassword;
    const confirmPassword = this.passwordForm.value.confirmPassword;

    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      this.passwordForm.get('confirmPassword').setErrors({ 'passwordMismatch': true });
    } else {
      this.passwordForm.get('confirmPassword').setErrors(null);
    }
  }
  // isNewPasswordSameAsOldPassword(): boolean {
  //   const oldPassword = this.passwordForm.get('oldPassword').value;
  //   const newPassword = this.passwordForm.get('newPassword').value;

  //   return oldPassword === newPassword;
  // }
  // isNewPasswordSameAsOldPassword(): boolean {
  //   const oldPassword = this.passwordForm.get('oldPassword').value;
  //   const newPassword = this.passwordForm.get('newPassword').value;

  //   return oldPassword === newPassword && oldPassword !== '';
  // }

}
