import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageGenresService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getInterests(data: any) {
        return this.http.post(this.url + 'interest/admin/list', data);
    }

    createInterests(data: any) {
        return this.http.post(this.url + 'interest/admin/add', data);
    }

    updateCommunity(data: any) {
        return this.http.put(this.url + 'api/genere/update/' + data._id, data);
    }
    updatePartnersOrder(data: any) {
        // const httpOptions = {
        //   headers: new HttpHeaders({}), body: data
        // };
        return this.http.put(this.url + 'api/genere/update-postion', data);
      }

    deleteCommunity(data: any) {
        const httpOptions = {
            headers: new HttpHeaders({}), body:data
        };
        return this.http.delete(this.url + 'api/genere/delete/'+data._id, httpOptions);
    }

    importInterest(data: any) {
        return this.http.post(this.url + 'import/areaOfInterest', data);
    }


    uploadImage(data:any){
        return this.http.post(this.url + 'user/images', data);
    }


    createService(data:any){
        return this.http.post(this.url + 'service/create', data);
    }

    getServices(data:any){
        return this.http.post(this.url + 'service/list', data);
    }

    deleteService(data:any){
        return this.http.post(this.url + 'interest/admin/delete', data);
    }

    editService(data:any){
        return this.http.post(this.url + 'interest/admin/edit', data);
    }

    createCommunity(data:any){
        return this.http.post(this.url + "api/genere/create",data);
    }


    getCommunityList(data:any){
        return this.http.get(this.url + 'api/genere/admin-list', data);
    }

    deleteManageInterest(data:any){
        return this.http.post(this.url + "interest/admin/delete", data)
    }

}