import { Component, OnInit,AfterViewInit,DoCheck, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/auth/services/auth.service';




@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  isReportedUsersActive: boolean = false;

  
  isMenuOpen:false
  modalRef: any;
  currentUser: any;
  isDropDownVisible: boolean;
  isDropDownVisible1: boolean;
  isManageReportedContentActive: boolean

  currentRoute: string;
  isManageReportedContentActive1: boolean
  constructor(    private modalService: BsModalService,    private authService: AuthService, public router:Router
   ) {
    this.isDropDownVisible = false
    this.isDropDownVisible1 = false
    this.isManageReportedContentActive = false
this.currentRoute = this.router.url;
this.isManageReportedContentActive1 = false
  }
  isActive1(url: string): boolean {
    return this.router.url === url || this.router.url.startsWith(url);
}



  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }
  ngDoCheck(){
  
    if(window.location.pathname.indexOf('coupon') != -1){
      document.getElementById('couponmenu').scrollIntoView();
    }else if(window.location.pathname.indexOf('coordinates') != -1){
      document.getElementById('Coordinates').scrollIntoView();
    }else if(window.location.pathname.indexOf('notifications') != -1){
      document.getElementById('notifications').scrollIntoView();
    }else if(window.location.pathname.indexOf('event-sponsors') != -1){
      document.getElementById('Sponsors').scrollIntoView();
    }else if(window.location.pathname.indexOf('treasure') != -1){
      document.getElementById('Treasure').scrollIntoView();
    }else if(window.location.pathname.indexOf('more-sections') != -1){
      document.getElementById('More-Section').scrollIntoView();
    }else if(window.location.pathname.indexOf('home-partners') != -1){
      document.getElementById('home-partners').scrollIntoView();
    }
  

  }

  // define a boolean flag variable
showReportedPostsAndCommentsDropdowns: boolean = true;

// function to handle click on "Manage Reported Content" dropdown
onManageReportedContentClick() {
  // set the flag variable to false to hide the "Manage Reported Posts" and "Manage Reported Comments" dropdowns
  this.showReportedPostsAndCommentsDropdowns = false;
}

// function to handle click on any other dropdown
onOtherDropdownClick() {
  // set the flag variable to true to show the "Manage Reported Posts" and "Manage Reported Comments" dropdowns
  this.showReportedPostsAndCommentsDropdowns = true;
}



  
#isEnabled: boolean = false;
isActive: boolean
isActiveLink = false;
  
get isEnabled(): boolean {
  return this.#isEnabled;
}

toggleEnabled() {
  this.#isEnabled = !this.#isEnabled;
}
isContentDropdownOpen = false;
isPostsDropdownOpen = false;
isCommentsDropdownOpen = false;

toggleContentDropdown() {
  this.isContentDropdownOpen = !this.isContentDropdownOpen;
  if (this.isContentDropdownOpen) {
    this.isPostsDropdownOpen = false;
    this.isCommentsDropdownOpen = false;
  }
}

togglePostsDropdown() {
  this.isPostsDropdownOpen = !this.isPostsDropdownOpen;
  if (this.isPostsDropdownOpen) {
    this.isContentDropdownOpen = false;
    this.isCommentsDropdownOpen = false;
  }
}

toggleCommentsDropdown() {
  this.isCommentsDropdownOpen = !this.isCommentsDropdownOpen;
  if (this.isCommentsDropdownOpen) {
    this.isContentDropdownOpen = false;
    this.isPostsDropdownOpen = false;
  }
}


  logout() {
    this.modalRef.hide();
    // this.authService.logout({id:this.currentUser._id}).subscribe((res:any) =>{
    //   console.log(res.status);
    //   if(res){
        localStorage.removeItem("currentUser");
    //   }
    // });
    this.router.navigateByUrl("/auth/login");
  }

   // modal lougout confirmation
   openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  confirm() {
    this.logout();
  }
  cancel() {
    this.modalRef.hide();
  }

  
}
