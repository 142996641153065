<!-- <app-layout *ngIf="userType == 1"></app-layout>
<app-header *ngIf="userType != 1"></app-header>
<app-chat-sidebar *ngIf="userType != 1"></app-chat-sidebar> -->

<!-- <app-layout></app-layout>
<div class="content"> -->
<!-- <div class="views-header">
        <div class="breadcrmb"> <span class="breadcrumb-link"> Change Password</span></div>
    </div> -->

<!-- <div class="edit-profile">
        <div class="row justify-content-center">
            <div class="col-md-5">
                <form [formGroup]="passwordForm">
                    <div class="change-pass">
                        <div class="form-group">
                            <label for="">Old Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide ? 'password' : 'text'" value=""
                                    formControlName="oldPassword">
                                <button mat-icon-button matSuffix (click)="hide = !hide"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.oldPassword.errors">
                                    <span *ngIf="p.oldPassword.errors.required">
                                        Old password is required.
                                    </span>
                                    <span *ngIf="p.oldPassword.errors.minlength">
                                        Password must have at least 6 characters.
                                    </span>
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <label for="">New Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide1 ? 'password' : 'text'" value=""
                                    formControlName="newPassword">
                                <button mat-icon-button matSuffix (click)="hide1 = !hide1"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide1">
                                    <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.newPassword.errors">
                                    <span *ngIf="p.newPassword.errors.required">
                                        New password is required.
                                    </span>
                                    <span *ngIf="p.newPassword.errors.minlength">
                                        Password must have at least 6 or 8 characters.
                                    </span>
                                    <div *ngIf="p.confirmPassword.errors.pattern" style="display: flex;">
                                        Password should contain min 8 characters and A-Z , a-z, 0-9, !@#$%^&*().
                                    </div>
                                </div>
                            </mat-form-field>
                        </div>

                        <div class="form-group">
                            <label for="">Confirm Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                                <input matInput [type]="hide2 ? 'password' : 'text'" value=""
                                    formControlName="confirmPassword">
                                <button mat-icon-button matSuffix (click)="hide2 = !hide2"
                                    [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide2">
                                    <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                                <div class="alert alert-danger" *ngIf="changed && p.confirmPassword.errors">
                                    <span *ngIf="p.confirmPassword.errors.required">
                                        Confirm password is required.
                                    </span>
                                    <span *ngIf="p.confirmPassword.errors.minlength">
                                        Password must have at least 6 or 8 characters.
                                    </span>
                                    <span *ngIf="p.confirmPassword.errors.pattern">
                                        Password should contain min 8 characters and A-Z , a-z, 0-9, !@#$%^&*().
                                    </span>
                                </div>
                                <span *ngIf="!match" class="alert alert-danger">
                                    Password doesn't match.
                                </span>
                            </mat-form-field>
                        </div>

                        <div class="form-group text-center">
                            <button class="btn btn-blue" (click)="changePassword()"> Change Password</button>
                            <p class="pass-change-msg" *ngIf="passChangeMsg">Password Changed Successfully!</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div> -->
<!-- </form> -->

<app-layout></app-layout>
<div class="content">
    <!-- <div class="views-header">
        <div class="breadcrmb"> <span class="breadcrumb-link"> Change Password</span></div>
    </div> -->

    <div class="edit-profile">
        <div class="row justify-content-center">
            <div class="col-md-5">
                <form [formGroup]="passwordForm">
                    <div class="change-pass">
                        <div class="form-group">
                            <label for="">Old Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                              <input matInput [type]="hide ? 'password' : 'text'"
                                [(ngModel)]="passwordForm.value.oldPassword" formControlName="oldPassword"
                                (input)="checkFieldsEntered()">
                              <button mat-icon-button matSuffix aria-label="Toggle password visibility"
                                (click)="hide = !hide" disableRipple>
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <div class="alert alert-danger"
                                *ngIf="passwordForm.get('oldPassword').invalid && passwordForm.get('oldPassword').dirty">
                                <span *ngIf="passwordForm.get('oldPassword').errors?.required">
                                  Old password is required.
                                </span>
                              </div>
                            </mat-form-field>
                          </div>
                          
                          
                          
                          
                          
                          
                          
                          
                          <div class="form-group">
                            <label for="">New Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                              <input matInput [type]="hide1 ? 'password' : 'text'"
                                [(ngModel)]="passwordForm.value.newPassword" formControlName="newPassword"
                                (input)="checkFieldsEntered()" (input)="isNewPasswordSameAsOldPassword()">
                              <button mat-icon-button matSuffix aria-label="Toggle password visibility"
                                (click)="hide1 = !hide1" disableRipple>
                                <mat-icon>{{hide1 ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <div class="alert alert-danger"
                                *ngIf="passwordForm.get('newPassword').invalid && passwordForm.get('newPassword').dirty">
                                <span *ngIf="passwordForm.get('newPassword').errors?.required">
                                  New password is required.
                                </span>
                                <span
                                  *ngIf="strongPasswordError && passwordForm.get('newPassword').errors?.pattern && !passwordForm.get('newPassword').errors?.required">
                                  Please choose a stronger password. It should contain at least 6 characters, 1 lowercase letter,
                                  1 uppercase letter, 1 special character, and 1 number.
                                </span>
                              </div>
                            </mat-form-field>
                          </div>
                          






<!-- 
                        <div class="form-group" style="padding-top: 10px;">
                            <label for="">Confirm Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                              <input matInput [type]="hide2 ? 'password' : 'text'" [(ngModel)]="passwordForm.value.confirmPassword"
                                formControlName="confirmPassword" (input)="checkFieldsEntered()">
                              <button mat-icon-button matSuffix (click)="hide2 = !hide2" [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide2">
                                <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <div *ngIf="passwordForm.get('confirmPassword').invalid && passwordForm.get('confirmPassword').dirty">
                                <div class="alert alert-danger" *ngIf="passwordForm.get('confirmPassword').errors?.required">
                                  Confirm password is required.
                                </div>
                              </div>
                              <div *ngIf="passwordForm.get('confirmPassword').dirty">
                                <div class="alert alert-danger" *ngIf="!passwordForm.get('confirmPassword').errors?.required && !match">
                                  Please make sure your passwords match.
                                </div>
                              </div>
                            </mat-form-field>
                          </div> -->

                          <div class="form-group" style="padding-top: 10px;">
                            <label for="">Confirm Password</label>
                            <mat-form-field appearance="fill" class="w-100">
                              <input matInput [type]="hide2 ? 'password' : 'text'" [(ngModel)]="passwordForm.value.confirmPassword"
                                formControlName="confirmPassword" (input)="checkFieldsEntered()">
                              <button mat-icon-button matSuffix aria-label="Toggle password visibility" (click)="hide2 = !hide2" disableRipple>
                                <mat-icon>{{hide2 ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </button>
                              <div *ngIf="passwordForm.get('confirmPassword').invalid && passwordForm.get('confirmPassword').dirty">
                                <div class="alert alert-danger" *ngIf="passwordForm.get('confirmPassword').errors?.required">
                                  Confirm password is required.
                                </div>
                              </div>
                              <div *ngIf="passwordForm.get('confirmPassword').dirty">
                                <div class="alert alert-danger" *ngIf="!passwordForm.get('confirmPassword').errors?.required && !match">
                                  Please make sure your passwords match.
                                </div>
                              </div>
                            </mat-form-field>
                          </div>
                          
                          


                        <div class="form-group text-center">
                            <button class="btn btn-blue" (click)="changePassword()"
                                [disabled]="!passwordForm.valid || !allFieldsEntered || !match">
                                Save
                            </button>
                            <p class="pass-change-msg" *ngIf="passChangeMsg">Password Changed Successfully!</p>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- </form> -->